<template>
    <div>
      <Navbar />
      <CancelCondition />
      <Footer />
    </div>
  </template>
  
  <script>
  import CancelCondition from "../components/information/CancelCondition.vue";
  import Footer from "../components/shared/Footer.vue";
  import Navbar from "../components/shared/Navbar.vue";
  
  export default {
    components: {
      Navbar,
      Footer,
      CancelCondition,
    },
  };
  </script>
  
  <style>
  </style>